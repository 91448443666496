import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  colors: {
    brand: {
      50: "#191919",
      100: "#486ab4",
      200: "#ffff",
      300: "#58489e",
      400: "#29a6de",
      500: "#009dff",
      600: "#0b5bd7",
      700: "#0a53be",
      800: "#064397",
      900: "#052b6c"
    }
  },
  fonts: {
    body: "Helvetica Neue, sans-serif",
    heading: "Georgia, serif",
    mono: "Menlo, monospace"
  }
})

export default theme;