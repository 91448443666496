import { ReactElement } from 'react';
import { Box, GridItem, Grid, Icon, Text, Center, Flex } from '@chakra-ui/react';
import fluxo from '../Assets/images/fluxo.png';
import setores from '../Assets/images/setores.png';

export default function Feature() {
  return (
    <Box p={12} bg={'brand.50'}>
      <Grid templateColumns='repeat(5, 1fr)' gap={4}>
        <GridItem colSpan={2}>
          <Flex flexDirection='column' justifyContent='center' alignItems='center' gap='2'>
            <img src={fluxo} alt="" />
            <Text fontSize='2xl' color={'brand.200'}>Fluxo Contínuo</Text>
          </Flex>
        </GridItem>
        <GridItem colStart={4} colEnd={6}>
          <Flex flexDirection='column' justifyContent='center' alignItems='center' gap='2'>
            <img src={setores} alt="" />
            <Text fontSize='2xl' color={'brand.200'}>Setores Internos Integrados</Text>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
