import { ReactNode } from 'react';
import { Center, Image, Flex } from '@chakra-ui/react'
import golgif from '../Assets/images/gol.gif';

export default function Simple() {
  return (
    <Center bg={'brand.50'} w='100%' py={4} color='white'>
      <Image
        alt={'Login Image'}
        objectFit={'cover'}
        w='100%'
        h={'80vh'}
        src={golgif}
      />
    </Center>
  );
}