import React from 'react';
import logo from './logo.svg';
import './App.css';

import NavBar from './Components/Navbar';
import Hero from './Components/Hero';
import Feature from './Components/Feature';
import InfoBox from './Components/InfoBox';
import BoxGif from './Components/BoxGif';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Hero />
      <Feature />
      <InfoBox />
      <BoxGif />
      <Footer />
    </div>
  );
}

export default App;
