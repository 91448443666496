import { ReactElement } from 'react';
import { Box, SimpleGrid, List, Text, ListItem, ListIcon } from '@chakra-ui/react';
import { MdCheckCircle } from "react-icons/md";

export default function InfoBox() {
  return (
    <Box p={10}>
      <SimpleGrid minChildWidth='120px' spacing='40px'>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Text color='brand.50' fontWeight={'bold'} fontSize='3xl'>Software ERP de Gestão Empresarial - SaaS</Text>
        </Box>
        <Box display={'flex'} justifyContent={'space-around'} textAlign={'left'}>
          <Box>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='brand.500' />
                Compras
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='brand.500' />
                Estoque
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='brand.500' />
                Faturamento
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='brand.500' />
                Fiscal
              </ListItem>
            </List>
          </Box>

          <Box>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='brand.500' />
                Financeiro
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='brand.500' />
                OMS
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='brand.500' />
                Indústria
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='brand.500' />
                Ordem de Serviço
              </ListItem>
            </List>
          </Box>

        </Box>
      </SimpleGrid>
    </Box>
  );
};
